@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


/* Custom CSS for Fade-In Animation */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.fade-in {
    animation: fadeIn 1s ease-out;
}

.fade-in-delay {
    animation: fadeIn 1s ease-out 0.5s;
    animation-fill-mode: forwards;
}

.fade-in-stagger-1 { 
    animation: fadeIn 1s ease-out 0.5s forwards; 
}

.fade-in-stagger-2 { 
    animation: fadeIn 1s ease-out 1s forwards; 
}